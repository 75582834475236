import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import copyToClipboard from 'copy-to-clipboard';
import Papa from 'papaparse';
import { URL } from '../../../redux/common/url';

export const handleCopy = (reportData) => {
  const copyText = reportData.map(row => [
    row.id, row.source_hydrant_center?.address, row.destination?.address, row.date, row.date,
    row.source_hydrant_center?.station_name, row.user?.mobile, row.vehicle?.registration_number,
    row.total_distance, row.source_hydrant_center?.remark
  ].join('\t')).join('\n');
  copyToClipboard(copyText);
  alert('Data copied to clipboard');
};

export const handleCSV = (reportData) => {
  const csv = Papa.unparse(reportData.map(row => ({
    "S.NO.": row.id,
    "Job Id": row.id,
    "Pickup location": row.source_hydrant_center?.address,
    "Drop location": row.destination?.address,
    "Pickup Date / time": row.date,
    "End Date / time": row.date,
    "Trip Duration": "",
    "Name": row.source_hydrant_center?.station_name,
    "Number": row.user?.mobile,
    "Tanker Number": row.vehicle?.registration_number,
    "Distance": row.total_distance,
    "Amount": "",
    "Remarks": row.source_hydrant_center?.remark,

  })));
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  saveAs(blob, 'data.csv');
};

export const handleExcel = (reportData) => {
  const worksheet = XLSX.utils.json_to_sheet(reportData.map(row => ({
    "S.NO.": row.id,
    "Job Id": row.id,
    "Pickup location": row.source_hydrant_center?.address,
    "Drop location": row.destination?.address,
    "Pickup Date / time": row.date,
    "End Date / time": row.date,
    "Trip Duration": "",
    "Name": row.source_hydrant_center?.station_name,
    "Number": row.user?.mobile,
    "Tanker Number": row.vehicle?.registration_number,
    "Distance": row.total_distance,
    "Amount": "",
    "Remarks": row.source_hydrant_center?.remark,

  })));
  const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
  saveAs(blob, 'data.xlsx');
};

export const handlePDF = (reportData) => {
  const doc = new jsPDF();
  const tableColumn = ["S.NO.", "Job Id", "Pickup location", "Drop location", "Pickup Date / time", "End Date / time", "Trip Duration", "Name", "Number", "Tanker Number", "Distance", "Amount", "Remarks"];
  const tableRows = [];

  reportData.forEach((row, index) => {
    const rowData = [
      index + 1, row.id, row.source_hydrant_center?.address, row.destination?.address, row.date, row.date, "",
      row.source_hydrant_center?.station_name, row.user?.mobile, row.vehicle?.registration_number, row.total_distance,
      "", row.source_hydrant_center?.remark,
    ];
    tableRows.push(rowData);
  });

  doc.autoTable({
    head: [tableColumn],
    body: tableRows
  });

  doc.save('data.pdf');
};


