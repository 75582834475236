export const LOADING= "LOADING";
//user constant
export const LOGIN = "LOGIN";
export const REGISTER = "REGISTER";

export const  ROLES_LIST= "ROLES_LIST"



export const  GET_DASHBOARD = "GET_DASHBOARD"



//university Constant
export const  GET_UNIVERSITIES_LIST = "GET_UNIVERSITIES_LIST"
export const  GET_UNIVERSITIES_BY_ID = "GET_UNIVERSITIES_BY_ID"
export const  GET_UNIVERSITIES = "GET_UNIVERSITIES"
export const  GET_ALL_UNIVERSITIES_COMMENT = "GET_ALL_UNIVERSITIES_COMMENT"
export const  ADD_UNIVERSITIES_COMMENT = "ADD_UNIVERSITIES_COMMENT"
export const  GET_RANKING_UNIVERSITIES = "GET_RANKING_UNIVERSITIES"
export const  GET_UNIVERSITIES_BY_SLUG = "GET_UNIVERSITIES_BY_SLUG"




// permission
export const  GET_PERMISSION_LISTS = "GET_PERMISSION_LISTS"
export const  GET_ROLES_LISTS = "GET_ROLES_LISTS"
export const  GET_RATE_LISTS = "GET_RATE_LISTS"
export const  GET_CIRCLE_LISTS = "GET_CIRCLE_LISTS"
export const  GET_USERS_LISTS = "GET_USERS_LISTS"
export const  GET_USERS_DETAILS= "GET_USERS_DETAILS"
export const  GET_ALL_CRICLE= "GET_ALL_CRICLE"
export const  GET_ALL_PROFILE_ROLL= "GET_ALL_PROFILE_ROLL"

export const  VEHICLE_LIST_VENDOR= "VEHICLE_LIST_VENDOR"

export const  PROFILE_LIST= "PROFILE_LIST"
export const  VEHICLE_LIST= "VEHICLE_LIST"
export const  CERCLE_LIST= "CERCLE_LIST"
export const  STATION_LIST= "STATION_LIST"
export const  STATION_DETAILS= "STATION_DETAILS"
export const  VEHICLE_DETAILS= "VEHICLE_DETAILS"
export const  DESTINATION_DETAILS= "DESTINATION_DETAILS"
export const  ASSIGN_DRIVER= "ASSIGN_DRIVER"
export const  OTHER_LINK= "OTHER_LINK"
export const  SETTING= "SETTING"

export const  GET_ALL_BOOKING= "GET_ALL_BOOKING"

export const  GET_USER_FOR_LOGIN= "GET_USER_FOR_LOGIN"












