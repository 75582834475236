import { 
  LOADING,

  GET_PERMISSION_LISTS,
  GET_ROLES_LISTS,
  GET_RATE_LISTS,
  GET_CIRCLE_LISTS,
  GET_USERS_LISTS,
  GET_USERS_DETAILS,
  GET_ALL_CRICLE,
  PROFILE_LIST,
  VEHICLE_LIST,
  VEHICLE_DETAILS,
  CERCLE_LIST,
  STATION_LIST,
  STATION_DETAILS,
  DESTINATION_DETAILS,
  ASSIGN_DRIVER,
  OTHER_LINK,
  SETTING,
  ROLES_LIST,
  GET_USER_FOR_LOGIN,
  GET_ALL_PROFILE_ROLL,
  VEHICLE_LIST_VENDOR,
  GET_ALL_BOOKING,
  GET_DASHBOARD

} from "../common/constant";
 type stateType = {
  loading:boolean;
  get_per_list: any;
  get_role_list: any;
  counsellordetail: any;
  coachAndCounsellor: any;
  counsellorinfo: any;
  counsellordetailBySlug: any;
  get_rate_list: any;
  get_circle_list: any;
  get_users_lists: any;
  get_users_details: any;
  get_all_cricle: any;
  profile_list: any;
  vehicle_list: any;
  vehicle_details: any;
  cercle_list: any;
  station_list: any;
  station_Details: any;
  destionation_Details: any;
  assign_driver: any;
  other_link: any;
  setting: any;
  roles_list: any;
  get_user_for_login: any;
  get_all_profile_roll: any;
  vehicle_list_vendor: any;
  get_all_booking: any;
  get_dashboard: any;
}
const initialState : stateType = {
    loading:false,
    get_dashboard: [],
    get_per_list: [],
    get_role_list: [],
    counsellordetail: [],
    coachAndCounsellor: [],
    counsellorinfo: [],
    counsellordetailBySlug: [],
    get_all_cricle: [],
    cercle_list: [],
    roles_list: [],
    get_rate_list: {},
    get_circle_list: {},
    get_users_details: {},
    get_all_profile_roll: [],
    get_users_lists: [],
    profile_list: {},
    vehicle_list: {},
    vehicle_details: {},
    station_list: {},
    station_Details: {},
    destionation_Details: {},
    assign_driver: {},
    other_link: {},
    setting: {},
    get_user_for_login: {},
    vehicle_list_vendor: {},
    get_all_booking: {}
};

export default (state = initialState, action:any) => {
  switch (action.type) {
    case GET_DASHBOARD:
      return { ...state, get_dashboard: action.payload};
    case LOADING:
      return { ...state, loading: action.payload};
    case GET_PERMISSION_LISTS:
      return { ...state, get_per_list: action.payload};
    case GET_ROLES_LISTS:
      return { ...state, get_role_list: action.payload};
    case GET_RATE_LISTS:
      return { ...state, get_rate_list: action.payload};
    case GET_CIRCLE_LISTS:
      return { ...state, get_circle_list: action.payload};
    case GET_USERS_LISTS:
      return { ...state, get_users_lists: action.payload};
    case GET_ALL_PROFILE_ROLL:
      return { ...state, get_all_profile_roll: action.payload};
    case GET_USERS_DETAILS:
      return { ...state, get_users_details: action.payload};
    case GET_ALL_CRICLE:
      return { ...state, get_all_cricle: action.payload};
    case PROFILE_LIST:
      return { ...state, profile_list: action.payload};
    case VEHICLE_LIST:
      return { ...state, vehicle_list: action.payload};
    case ROLES_LIST:
        return { ...state, roles_list: action.payload};
    case VEHICLE_DETAILS:
      return { ...state, vehicle_details: action.payload};
    case CERCLE_LIST:
      return { ...state, cercle_list: action.payload};
    case STATION_LIST:
      return { ...state, station_list: action.payload};
    case STATION_DETAILS:
      return { ...state, station_Details: action.payload};
    case DESTINATION_DETAILS:
      return { ...state, destionation_Details: action.payload};
    case ASSIGN_DRIVER:
      return { ...state, assign_driver: action.payload};
    case OTHER_LINK:
      return { ...state, other_link: action.payload};
    case SETTING:
      return { ...state, setting: action.payload};
    case GET_USER_FOR_LOGIN:
      return { ...state, get_user_for_login: action.payload};
    case VEHICLE_LIST_VENDOR:
      return { ...state, vehicle_list_vendor: action.payload};
    case GET_ALL_BOOKING:
      return { ...state, get_all_booking: action.payload};
      
    default:
      return state;
  }
};