import React, {useEffect, useMemo, useState} from 'react'
import ReactPaginate from 'react-paginate'
import {useDispatch, useSelector} from 'react-redux'
import {adminAction} from '../../redux/common/action'
import {Pagination, TableHeader} from './Table'
import PaginationComponent from './Table/Pagination/Pagination'
import {useFormik} from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
import Dropdown from 'react-bootstrap/Dropdown'
// modal
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import {Link, Navigate, useNavigate} from 'react-router-dom'
import {URL} from '../../redux/common/url'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'
import moment from 'moment'

import {AsyncPaginate} from 'react-select-async-paginate'

import DatePicker from 'react-datepicker'

// @ts-ignore

// import {CKEditor} from '@ckeditor/ckeditor5-react'

// @ts-ignore
// import Editor from 'ckeditor5-custom-build/build/ckeditor'

// image uploader
// import ImageUploading from 'react-images-uploading'
import {toast} from 'react-toastify'
import {getUserByToken, loginUser} from '../modules/auth/core/_requests'

import {useAuth} from '../modules/auth/core/Auth'
import {Input} from 'antd'

const userAvatarClass =
  'symbol-35px symbol-md-40px rounded border border-3 border-gray-300 shadow-sm'

const loginSchema = Yup.object().shape({
  role_name: Yup.string()
    // .role_name('Wrong role_name format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('product is required'),
  role_description: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('tegs is required'),
})

const initialValues = {
  role_name: '',
  role_description: '',
}

interface Option {
  value: string
  label: string
}

interface PaginatedResponse {
  results: Option[]
  has_more: boolean
}

function UserLogin() {
  const [sortingField, setSortingField] = useState('')
  const [sortingOrder, setSortingOrder] = useState('asc')
  // const [comments, setComments] = useState([])
  // const [totalItems, setTotalItems] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(1)
  const navigate = useNavigate()
  // const [showVisible, setShowVisible] = useState<boolean>(false);

  const [itemsPerPage, setitemsPerPage] = useState(10)

  // const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState('')
  // const [sorting, setSorting] = useState({ field: '', order: '' })

  // deta table  state end

  // modal

  // const [show, setShow] = useState(false)

  // const [addpermission, setaddpermission] = useState(false)
  // const [showDelelt, setShowDelete] = useState(false)

  // // date
  // const [startDate, setStartDate] = useState(new Date())
  // const [startDateup, setStartDateup] = useState(new Date())

  // const [showUpdate, setShowUpdate] = useState(false)
  // const handleCloseUpdate = () => setShowUpdate(false)
  // const handleClose = () => setShow(false)

  // const handleCloseaddpermission = () => setaddpermission(false)
  // const handleCloseDelete = () => setShowDelete(false)
  // const handleShow = () => setShow(true)

  // modal end

  // api call

  const dispatch = useDispatch()
  const [roledata, setroledata] = useState<any>({
    role_name: '',
    role_description: '',
    meta_title: '',
    meta_description: '',
  })
  // const [loading, setLoading] = useState(false)
  const [roleID, setroleID] = useState<any>('')
  // const [productID, setproductID] = useState<any>('')
  const [pageNo, setpageNo] = useState<any>(1)

  // get api data

  // image uploder

  const get_role_list: any[] = useSelector((state: any) => {
    return state.admin.get_role_list ? state.admin.get_role_list : []
  })
  // const get_all_cricle: any[] = useSelector((state: any) =>
  //   state.admin.get_all_cricle ? state.admin.get_all_cricle : []
  // )
  const get_product_listmmm: any = useSelector((state: any) =>
    state.admin.get_user_for_login ? state.admin.get_user_for_login : {}
  )

  const get_product_list: any = useSelector((state: any) => {
    return state.admin.get_all_profile_roll ? state.admin.get_all_profile_roll : []
  })

  console.log('get_product_list', get_product_list)

  useEffect(() => {
    if (!roleID) {
      setroleID(get_role_list[0]?.id)
    }
  }, [get_role_list])

  useEffect(() => {
    if (roleID) {
      dispatch(adminAction.getusersprofilerollwise({page: pageNo, role_id: roleID, search}))
    }
  }, [roleID, search, pageNo])

  useEffect(() => {
    // dispatch(adminAction.getPermissionsList('dhsg'))
    // dispatch(adminAction.getUsersForLogin(1))
    dispatch(adminAction.getRoleList(''))
    // dispatch(adminAction.getgetAllCircles(''))
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {}
  }, [])

  useEffect(() => {
    dispatch(adminAction.getUsersForLogin({page: pageNo, search}))
  }, [pageNo, search])

  const hendleToaddPer = async (e: any) => {
    const url = `${URL.RoutingURL}/auth?user_name=${e.user_name}&role_id=${e.role_id}&password=${e.password}`
    window.open(url, '_blank')
  }

  const comments = get_product_list?.data ? get_product_list?.data : []

  const headers = [
    {name: 'S.No.', field: '_id', sortable: false},
    {name: 'Name', field: 'name', sortable: true},
    {name: 'User name', field: 'user_name', sortable: false},
    // { name: 'Email', field: 'email', sortable: false },
    // { name: 'Mobile', field: 'active', sortable: false },
    {name: 'Action', field: 'action', sortable: true},
  ]

  const handlePageClick = (event: any) => {
    // const newOffset = event.selected % (comments.length / 2);
    // setCurrentPage(newOffset + 1);
    // setItemOffset(newOffset * itemsPerPage);

    const data = event?.selected + 1

    setpageNo(data)

    // if (!userSearch) {
    // dispatch(adminAction.getUsersForLogin(data))

    return () => {}
    // } else {
    //   dispatch(
    //     ShoetoggelAction.searchUser({ pageNumber: data, key: userSearch })
    //   );

    //   return () => {};
    // }
  }

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage

    setPageCount(Math.ceil(get_product_list?.total / itemsPerPage))
  }, [itemOffset, itemsPerPage, comments])

  // Table shoorting Function

  // const onSortingChange = (field: any) => {
  //   const order = field === sortingField && sortingOrder === 'asc' ? 'desc' : 'asc'

  //   setSortingField(field)
  //   setSortingOrder(order)
  //   setSorting({ field, order })
  // }

  return (
    <div className='d-flex flex-column flex-column-fluid'>
      <div id='kt_app_content' className='app-content flex-column-fluid'>
        <div id='kt_app_content_container' className='app-container container-xxl'>
          <div className='card card-flush'>
            <div className='card-header mt-6'>
              <div className='card-title'>
                <div
                  className='d-flex align-items-center position-relative my-1 me-5'
                  style={{
                    display: 'flex',
                    gap: '10px',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <Input
                    placeholder='input search '
                    className=' mt-3'
                    onChange={(e) => setSearch(e.target.value)}
                    style={{width: 200, padding: 10}}
                  />

                  <select
                    className='form-select form-select-white mt-3'
                    aria-label='Select Circle'
                    name='role_id'
                    onChange={(e) => {
                      setroleID(e.target.value)
                    }}
                  >
                    {get_role_list &&
                      get_role_list?.map((data, i) => {
                        if (data?.id == '10' || data?.id == '11' || data?.id == '9') {
                          return null
                        }

                        return (
                          <option value={data?.id} key={i}>
                            {data?.fullname}
                          </option>
                        )
                      })}
                  </select>
                </div>
                {/* <!--end::Search-->  */}
              </div>
              {/* <!--end::Card title-->  */}
              {/* <!--begin::Card toolbar-->  */}
              <div className='card-toolbar '>
                {/* <!--begin::Button-->  */}
                {/* <button
                          type='button'
                          className='btn btn-light-primary'
                          // data-bs-toggle='modal'
                          // data-bs-target='#kt_modal_add_permission'
                          onClick={handleShow}
                        >
                        
                          <span className='svg-icon svg-icon-3'>
                            <svg
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <rect
                                opacity='0.3'
                                x='2'
                                y='2'
                                width='20'
                                height='20'
                                rx='5'
                                fill='currentColor'
                              />
                              <rect
                                x='10.8891'
                                y='17.8033'
                                width='12'
                                height='2'
                                rx='1'
                                transform='rotate(-90 10.8891 17.8033)'
                                fill='currentColor'
                              />
                              <rect
                                x='6.01041'
                                y='10.9247'
                                width='12'
                                height='2'
                                rx='1'
                                fill='currentColor'
                              />
                            </svg>
                          </span>
                          Add Profile
                        </button> */}
                {/* <!--end::Button-->  */}
                {/* <Button variant='primary' onClick={handleShow}>
                        Launch demo modal
                      </Button> */}
              </div>
              {/* <!--end::Card toolbar-->  */}
            </div>
            {/* <!--end::Card header-->  */}
            {/* <!--begin::Card body-->  */}
            <div className='card-body pt-0'>
              {/* <!--begin::Table-->  */}
              <table
                className='table align-middle table-row-dashed fs-6 gy-5 mb-0'
                id='kt_permissions_table'
              >
                {/* <!--begin::Table head-->  */}
                <thead>
                  <tr>
                    {headers.map((data: any, i: any) => (
                      <th key={i}>
                        {data?.name}

                        {sortingField &&
                          sortingField === data?.field &&
                          (sortingOrder === 'asc' ? (
                            //   <FontAwesomeIcon icon='fa-solid fa-arrow-down' />
                            <i className='fa-solid fa-arrow-down'></i>
                          ) : (
                            <i className='fas fa-arrow-alt-up'></i>
                          ))}
                      </th>
                    ))}
                  </tr>

                  {/* <TableHeader
                          headers={headers}
                          onSorting={(field: any, order: any) => setSorting({field, order})}
                        /> */}
                  {/* <!--end::Table row-->  */}
                </thead>
                {/* <!--end::Table head-->  */}
                {/* <!--begin::Table body-->  */}
                <tbody className='fw-semibold text-gray-600'>
                  {comments?.map((data: any, i: any) => {
                    const So = 1 + i
                    const pgt1 = pageNo - 1
                    const pgt = pgt1 * 10
                    const soNo = pgt + So

                    return (
                      <tr key={i}>
                        {/* <!--begin::Name=-->  */}
                        {/* <td>User Management</td> */}
                        {/* <!--end::Name=-->  */}
                        {/* <!--begin::Assigned to=-->  */}
                        <td>
                          {soNo}
                          {/* <a
                                  href='../../demo1/dist/apps/user-management/roles/view.html'
                                  className='badge badge-light-primary fs-7 m-1'
                                >
                                  Administrator
                                </a> */}
                        </td>
                        {/* <!--end::Assigned to=-->  */}
                        {/* <!--begin::Created Date-->  */}

                        <td>{data?.name}</td>
                        <td>{data?.name}</td>
                        {/* <td>{data?.email}</td>
                        <td>{data?.mobile }</td> */}

                        <td>
                          {data?.user_id ? (
                            <button
                              data-id='2209'
                              onClick={() => {
                                hendleToaddPer(data)
                              }}
                              className='btn btn-sm btn-info viewItem'
                            >
                              {/* <i className='fas fa-eye'></i> */}
                              Login
                            </button>
                          ) : (
                            <div className='text-red-600' style={{color: 'red'}}>
                              Profile not assigned
                            </div>
                          )}

                          {/* <!--end::Menu--> */}
                          {/* </td> */}
                          {/* <button
                                    className='btn btn-icon btn-active-light-primary w-30px h-30px me-3'
                                    data-bs-toggle='modal'
                                    data-bs-target='#kt_modal_update_permission'
                                  >
                                   
                                    <span className='svg-icon svg-icon-3'>
                                      <svg
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                      >
                                        <path
                                          d='M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z'
                                          fill='currentColor'
                                        />
                                        <path
                                          opacity='0.3'
                                          d='M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z'
                                          fill='currentColor'
                                        />
                                      </svg>
                                    </span>
                                  
                                  </button> */}

                          {/* <!--end::Update-->  */}
                          {/* <!--begin::Delete-->  */}
                          {/* <button
                                    className='btn btn-icon btn-active-light-primary w-30px h-30px'
                                    data-kt-permissions-table-filter='delete_row'
                                  >
                                  
                                    <span className='svg-icon svg-icon-3'>
                                      <svg
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                      >
                                        <path
                                          d='M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z'
                                          fill='currentColor'
                                        />
                                        <path
                                          opacity='0.5'
                                          d='M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z'
                                          fill='currentColor'
                                        />
                                        <path
                                          opacity='0.5'
                                          d='M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z'
                                          fill='currentColor'
                                        />
                                      </svg>
                                    </span>
                                 
                                  </button> */}
                          {/* <!--end::Delete-->  */}
                        </td>
                        {/* <!--end::Action=-->  */}
                      </tr>
                    )
                  })}
                </tbody>
                {get_product_list?.totalDocs == '0' && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '400px',
                    }}
                  >
                    <h3>Record Not found</h3>
                  </div>
                )}

                {/* <!--end::Table body-->  */}
              </table>
              <ReactPaginate
                breakLabel='...'
                nextLabel='next'
                className='dataTables_paginate paging_simple_numbers category'
                onPageChange={handlePageClick}
                pageRangeDisplayed={2}
                pageCount={pageCount}
                previousLabel='previous'
                // renderOnZeroPageCount={null}
              />
              {/* </div> */}
              {/* <!--end::Table-->  */}
            </div>

            {/* <!--end::Card body-->  */}
          </div>
          {/* <!--end::Card-->  */}
          {/* <!--begin::Modals-->  */}
          {/* <!--begin::Modal - Add permissions-->  */}
          <div className='modal fade' id='kt_modal_add_permission' tabIndex={-1} aria-hidden='true'>
            {/* <!--begin::Modal dialog-->  */}
            <div className='modal-dialog modal-dialog-centered mw-650px'>
              {/* <!--begin::Modal content-->  */}
              <div className='modal-content'>
                {/* <!--begin::Modal header-->  */}
                <div className='modal-header'>
                  {/* <!--begin::Modal title-->  */}
                  <h2 className='fw-bold'>Add a Permission</h2>
                  {/* <!--end::Modal title-->  */}
                  {/* <!--begin::Close-->  */}
                  <div
                    className='btn btn-icon btn-sm btn-active-icon-primary'
                    data-kt-permissions-modal-action='close'
                  >
                    {/* <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->  */}
                    <span className='svg-icon svg-icon-1'>
                      <svg
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <rect
                          opacity='0.5'
                          x='6'
                          y='17.3137'
                          width='16'
                          height='2'
                          rx='1'
                          transform='rotate(-45 6 17.3137)'
                          fill='currentColor'
                        />
                        <rect
                          x='7.41422'
                          y='6'
                          width='16'
                          height='2'
                          rx='1'
                          transform='rotate(45 7.41422 6)'
                          fill='currentColor'
                        />
                      </svg>
                    </span>
                    {/* <!--end::Svg Icon-->  */}
                  </div>
                  {/* <!--end::Close-->  */}
                </div>
                {/* <!--end::Modal header-->  */}
                {/* <!--begin::Modal body-->  */}
                <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                  {/* <!--begin::Form-->  */}
                  <form id='kt_modal_add_permission_form' className='form' action='#'>
                    {/* <!--begin::Input group-->  */}
                    <div className='fv-row mb-7'>
                      {/* <!--begin::Label-->  */}
                      <label className='fs-6 fw-semibold form-label mb-2'>
                        <span className='required'>Permission Name</span>
                        <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='popover'
                          data-bs-trigger='hover'
                          data-bs-html='true'
                          data-bs-content='Permission names is required to be unique.'
                        ></i>
                      </label>
                      {/* <!--end::Label-->  */}
                      {/* <!--begin::Input-->  */}
                      <input
                        className='form-control form-control-solid'
                        placeholder='Enter a permission name'
                        name='permission_name'
                      />
                      {/* <!--end::Input-->  */}
                    </div>
                    {/* <!--end::Input group-->  */}
                    {/* <!--begin::Input group-->  */}
                    <div className='fv-row mb-7'>
                      {/* <!--begin::Checkbox-->  */}
                      <label className='form-check form-check-custom form-check-solid me-9'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          name='permissions_core'
                          id='kt_permissions_core'
                        />
                        <span className='form-check-label' /* for='kt_permissions_core' */>
                          Set as core permission
                        </span>
                      </label>
                      {/* <!--end::Checkbox-->  */}
                    </div>
                    {/* <!--end::Input group-->  */}
                    {/* <!--begin::Disclaimer-->  */}
                    <div className='text-gray-600'>
                      Permission set as a<strong className='me-1'>Core Permission</strong>will be
                      locked and
                      <strong className='me-1'>not editable</strong>in future
                    </div>
                    {/* <!--end::Disclaimer-->  */}
                    {/* <!--begin::Actions-->  */}
                    <div className='text-center pt-15'>
                      <button
                        type='reset'
                        className='btn btn-light me-3'
                        data-kt-permissions-modal-action='cancel'
                      >
                        Discard
                      </button>
                      <button
                        type='submit'
                        className='btn btn-primary'
                        data-kt-permissions-modal-action='submit'
                      >
                        <span className='indicator-label'>Submit</span>
                        <span className='indicator-progress'>
                          Please wait...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      </button>
                    </div>
                    {/* <!--end::Actions-->  */}
                  </form>
                  {/* <!--end::Form-->  */}
                </div>
                {/* <!--end::Modal body-->  */}
              </div>
              {/* <!--end::Modal content-->  */}
            </div>
            {/* <!--end::Modal dialog-->  */}
          </div>
          {/* <!--end::Modal - Add permissions-->  */}
          {/* <!--begin::Modal - Update permissions-->  */}
          <div
            className='modal fade'
            id='kt_modal_update_permission'
            tabIndex={-1}
            aria-hidden='true'
          >
            {/* <!--begin::Modal dialog-->  */}
            <div className='modal-dialog modal-dialog-centered mw-650px'>
              {/* <!--begin::Modal content-->  */}
              <div className='modal-content'>
                {/* <!--begin::Modal header-->  */}
                <div className='modal-header'>
                  {/* <!--begin::Modal title-->  */}
                  <h2 className='fw-bold'>Update Permission</h2>
                  {/* <!--end::Modal title-->  */}
                  {/* <!--begin::Close-->  */}
                  <div
                    className='btn btn-icon btn-sm btn-active-icon-primary'
                    data-kt-permissions-modal-action='close'
                  >
                    {/* <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->  */}
                    <span className='svg-icon svg-icon-1'>
                      <svg
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <rect
                          opacity='0.5'
                          x='6'
                          y='17.3137'
                          width='16'
                          height='2'
                          rx='1'
                          transform='rotate(-45 6 17.3137)'
                          fill='currentColor'
                        />
                        <rect
                          x='7.41422'
                          y='6'
                          width='16'
                          height='2'
                          rx='1'
                          transform='rotate(45 7.41422 6)'
                          fill='currentColor'
                        />
                      </svg>
                    </span>
                    {/* <!--end::Svg Icon-->  */}
                  </div>
                  {/* <!--end::Close-->  */}
                </div>
                {/* <!--end::Modal header-->  */}
                {/* <!--begin::Modal body-->  */}
                <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                  {/* <!--begin::Notice-->  */}
                  {/* <!--begin::Notice-->  */}
                  <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6'>
                    {/* <!--begin::Icon-->  */}
                    {/* <!--begin::Svg Icon | path: icons/duotune/general/gen044.svg-->  */}
                    <span className='svg-icon svg-icon-2tx svg-icon-warning me-4'>
                      <svg
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <rect
                          opacity='0.3'
                          x='2'
                          y='2'
                          width='20'
                          height='20'
                          rx='10'
                          fill='currentColor'
                        />
                        <rect
                          x='11'
                          y='14'
                          width='7'
                          height='2'
                          rx='1'
                          transform='rotate(-90 11 14)'
                          fill='currentColor'
                        />
                        <rect
                          x='11'
                          y='17'
                          width='2'
                          height='2'
                          rx='1'
                          transform='rotate(-90 11 17)'
                          fill='currentColor'
                        />
                      </svg>
                    </span>
                    {/* <!--end::Svg Icon-->  */}
                    {/* <!--end::Icon-->  */}
                    {/* <!--begin::Wrapper-->  */}
                    <div className='d-flex flex-stack flex-grow-1'>
                      {/* <!--begin::Content-->  */}
                      <div className='fw-semibold'>
                        <div className='fs-6 text-gray-700'>
                          <strong className='me-1'>Warning!</strong>By editing the permission name,
                          you might break the system permissions functionality. Please ensure you're
                          absolutely certain before proceeding.
                        </div>
                      </div>
                      {/* <!--end::Content-->  */}
                    </div>
                    {/* <!--end::Wrapper-->  */}
                  </div>
                  {/* <!--end::Notice-->  */}
                  {/* <!--end::Notice-->  */}
                  {/* <!--begin::Form-->  */}
                  <form id='kt_modal_update_permission_form' className='form' action='#'>
                    {/* <!--begin::Input group-->  */}
                    <div className='fv-row mb-7'>
                      {/* <!--begin::Label-->  */}
                      <label className='fs-6 fw-semibold form-label mb-2'>
                        <span className='required'>Permission Name</span>
                        <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='popover'
                          data-bs-trigger='hover'
                          data-bs-html='true'
                          data-bs-content='Permission names is required to be unique.'
                        ></i>
                      </label>
                      {/* <!--end::Label-->  */}
                      {/* <!--begin::Input-->  */}
                      <input
                        className='form-control form-control-solid'
                        placeholder='Enter a permission name'
                        name='permission_name'
                      />
                      {/* <!--end::Input-->  */}
                    </div>
                    {/* <!--end::Input group-->  */}
                    {/* <!--begin::Actions-->  */}
                    <div className='text-center pt-15'>
                      <button
                        type='reset'
                        className='btn btn-light me-3'
                        data-kt-permissions-modal-action='cancel'
                      >
                        Discard
                      </button>
                      <button
                        type='submit'
                        className='btn btn-primary'
                        data-kt-permissions-modal-action='submit'
                      >
                        <span className='indicator-label'>Submit</span>
                        <span className='indicator-progress'>
                          Please wait...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      </button>
                    </div>
                    {/* <!--end::Actions-->  */}
                  </form>
                  {/* <!--end::Form-->  */}
                </div>
                {/* <!--end::Modal body-->  */}
              </div>
              {/* <!--end::Modal content-->  */}
            </div>
            {/* <!--end::Modal dialog-->  */}
          </div>
          {/* <!--end::Modal - Update permissions-->  */}
          {/* <!--end::Modals-->  */}
        </div>
        {/* <!--end::Content container-->  */}
      </div>
      {/* <!--end::Content-->  */}
    </div>
  )
}

export default UserLogin
