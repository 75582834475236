import { FC, useEffect, useState } from "react";
import axios from "axios";
import { URL } from '../../../redux/common/url';
import { toast } from "react-toastify";
import { handleCopy, handleCSV, handleExcel, handlePDF } from '../Provisional/print';
import moment from 'moment';
import { Link } from "react-router-dom";

interface Value {
  id: string;
  name: string;
}

const Vef_Vehicle: FC = () => {
  const [vendor, setVendor] = useState<any[]>([]);
  const [vehicle, setVehicle] = useState<any[]>([]);
  const [circles, setCircles] = useState<Value[]>([]);
  const [reportData, setReportData] = useState([]);
  const [page, setPage] = useState<number>(1);
  const [vendorId, setVendorId] = useState<string>('');
  const [vehicleId, setVehicleId] = useState<string>('');
  const [fromDate, setFromDate] = useState<string>('');
  const [toDate, setToDate] = useState<string>('');
  const [name, setName] = useState<string>('');

  const user = JSON.parse(localStorage.getItem('access_admin_token')!).data;

  const fetchVehicles = async (vendorId: string) => {
    try {
      setVendorId(vendorId);
      const res = await axios.get(`${URL.API_BASE_URL}${URL.all_vendors_tanker}?vendor_id=${vendorId}&page=1`);
      if (res?.data?.results) {
        setVehicle(res?.data?.results);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCircles = async () => {
    try {
      const response = await axios.get(`${URL.API_BASE_URL + URL.getAllCircles}`);
      if (response?.data?.data) {
        setCircles(response?.data?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchVendors = async (circleId: string) => {
    try {
      // setVendorId(circleId);
      const res = await axios.get(`${URL.API_BASE_URL}${URL.getVendorList}?circle_id=${circleId}`);
      if (res?.data?.results) {
        setVendor(res?.data?.results);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getReports = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("kt-auth-react-v")!);
      const config = {
        headers: {
          Authorization: "Bearer " + token.api_token,
        },
      };

      const query = [
        `approved=accept`,
        `page=${page}`,
        vendorId && `vendor=${vendorId}`,
        vehicleId && `vehicle_id=${vehicleId}`,
        fromDate && `fromdate=${moment(fromDate).format('YYYY-MM-DD')}`,
        toDate && `todate=${moment(toDate).format('YYYY-MM-DD')}`,
        name && `address=${name}`,
      ].filter(Boolean).join('&');

      const res = await axios.get(`${URL.API_BASE_URL}${URL.getReports}?${query}`, config);

      if (res?.data?.data?.data) {
        setReportData(res?.data?.data?.data);
        setPage(res?.data?.data?.current_page);
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    fetchCircles();
    getReports();
  }, [name]);

  return (
    <div>
      <div className="d-flex justify-content-between">
        <h3 className="text-gray-600">Trip Report Vehicle Wise</h3>
        <p><span className="text-primary">Home</span> / Trip Report Vehicle Wise</p>
      </div>
      <div className="bg-transparent p-3 pt-6 shadow m-0 rounded-2xl">
        <div className="d-flex px-5 justify-content-between">
          <div className="me-3" style={{ position: 'relative' }}>
            <label><b>Circle</b></label>
            <select
              className="form-control"
              aria-label="Select example"
              name="circle_id"
              onChange={(e) => fetchVendors(e.target.value)}
            >
              <option value="">Select Circle</option>
              {circles.map((data, i) => (
                <option value={data.id} key={i}>{data.name}</option>
              ))}
            </select>
          </div>
          <div className="me-3" style={{ position: 'relative' }}>
            <label><b>Vendor</b></label>
            <select
              className="form-control"
              name="vendor_id"
              style={{ width: '200px' }}
              onChange={(e) => fetchVehicles(e.target.value)}
            >
              <option value="">Select</option>
              {vendor.length > 0 && vendor.map((item, index) => (
                <option value={item.id} key={index}>{item.name}</option>
              ))}
            </select>
          </div>
          <div className="me-3" style={{ position: 'relative' }}>
            <label><b>Vehicle</b></label>
            <select
              className="form-control"
              name="vehicle_id"
              style={{ width: '200px' }}
              onChange={(e) => setVehicleId(e.target.value)}
            >
              <option value="">Select</option>
              {vehicle.length > 0 && vehicle.map((item, index) => (
                <option value={item.vehicle_id} key={index}>{item.vehicle?.vehicle_name}</option>
              ))}
            </select>
          </div>
          <div className="me-3">
            <label><b>Date From:</b></label>
            <input
              className="form-control"
              type="date"
              onChange={(e) => setFromDate(e.target.value)}
            />
          </div>
          <div className="me-3">
            <label><b>Date To:</b></label>
            <input
              className="form-control"
              type="date"
              onChange={(e) => setToDate(e.target.value)}
            />
          </div>
          <div
            onClick={() => getReports()}
            className="mt-8"
            style={{ backgroundColor: '#52A841', color: 'white', height: '30px', width: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
          >
            View
          </div>
        </div>
        <div className="d-flex px-5 justify-content-between gap-4 align-items-center mt-4">
          <div className="d-flex gap-1">
            <div className="p-2" style={{ border: '1px solid #000', backgroundColor: '#ADAFAD', cursor: 'pointer' }} onClick={() => handleCopy(reportData)}>Copy</div>
            <div className="p-2" style={{ border: '1px solid #000', backgroundColor: '#ADAFAD', cursor: 'pointer' }} onClick={() => handleCSV(reportData)}>CSV</div>
            <div className="p-2" style={{ border: '1px solid #000', backgroundColor: '#ADAFAD', cursor: 'pointer' }} onClick={() => handlePDF(reportData)}>PDF</div>
            <div className="p-2" style={{ border: '1px solid #000', backgroundColor: '#ADAFAD', cursor: 'pointer' }} onClick={() => handleExcel(reportData)}>EXCEL</div>
          </div>
          <div>
            <input type="search" placeholder="Search by Name" className="form-control" onChange={(e) => setName(e.target.value)} />
          </div>
        </div>


        <div className="mt-9">
          <table className="table" style={{ 'fontSize': '12px', 'fontWeight': '300' }}>
            <thead className="table-light ">
              <tr>
                <th>S.NO.</th>
                <th>Job Id</th>
                <th>Pickup location</th>
                <th>Drop location</th>
                <th>Pickup Date / time</th>
                <th>End Date / time</th>
                <th>Trip Duration</th>
                <th>Name</th>
                <th>Number</th>
                <th>Tanker Number</th>
                <th>Distance</th>
                <th>Amount</th>
                <th>Remarks</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody style={{ 'maxHeight': '60vh', 'overflow': 'auto' }} >
              {
                reportData.length > 0 && reportData.map((item: any, index: any) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item?.id}</td>
                    <td>{item?.source_hydrant_center?.address}</td>
                    <td>{item?.destination?.address}</td>
                    <td>{item?.date}</td>
                    <td>{item?.date}</td>
                    <td></td>
                    <td>{item?.source_hydrant_center?.station_name}</td>
                    <td>{item?.user?.mobile}</td>
                    <td>{item?.vehicle?.registration_number}</td>
                    <td>{item?.total_distance}</td>
                    <td></td>
                    <td>{item?.source_hydrant_center?.remark}</td>
                    <td>
                      <Link to={`/view_details/${item?.id}/accept`}>
                        <button className="btn-group btn-group-sm bg-primary text-white">
                          View Details
                        </button>
                      </Link>

                    </td>
                  </tr>
                ))
              }
            </tbody >

            <tfoot>
              <tr>
                <td colSpan={6}></td>
                <td><b>Date</b></td>
                <td> 31-05-2024 to 31-05-2024</td>
                <td colSpan={2}></td>
                <td><b>Total</b></td>
                <td colSpan={2} className="text-center fs-5">908</td>
                <td ></td>

              </tr>
              <tr>
                <td colSpan={14} style={{ 'textAlign': 'center' }}>
                  This is System Generated Report it does not require any signature or stamp.
                </td>
              </tr>
            </tfoot>
          </table>
          <p>Showing 1 to 201 of 201 entries</p>
        </div>



      </div>
    </div>
  )
}

export default Vef_Vehicle