import {FC, useEffect, useState} from 'react'
import {useLayout} from '../../../_metronic/layout/core'
import {URL} from '../../../redux/common/url'
import clsx from 'clsx'
import axios from 'axios'
import {toast} from 'react-toastify'
import {handleCopy, handleCSV, handleExcel, handlePDF} from './print.js'
import {Link} from 'react-router-dom'
import ReactPaginate from 'react-paginate'

interface Value {
  id: string
  name: string
}

interface Option {
  id: string
}

const VehicalWise: FC = () => {
  const [vendor, setVendor] = useState([])
  const [circle_id, setCircle_id] = useState('')
  const [vendor_id, setVendor_id] = useState('')
  const [fromdate, setfromdate] = useState('')
  const [todate, settodate] = useState('')
  const [vehical_id, setVehical_id] = useState('')
  const [vehical, setVehical] = useState([])
  const [circles, setCircles] = useState<Value[]>([])
  const [name, setName] = useState<string>('')
  const [reportData, setReportData] = useState<any[]>([])
  const [approved, setApproved] = useState<Option[]>([])
  const [pageNo, setpageNo] = useState<any>(1)
  const [pageCount, setPageCount] = useState(0)
  function checkAllData(e: any) {
    if (!e) {
      setApproved([])
    } else {
      setApproved(reportData.map((data) => data.id))
    }
  }

  const handlePageClick = (event: any) => {
    // const newOffset = event.selected % (comments.length / 2);
    // setCurrentPage(newOffset + 1);
    // setItemOffset(newOffset * itemsPerPage);

    const data = event?.selected + 1

    setpageNo(data)

    // if (!userSearch) {
    // dispatch(adminAction.getCurrentBookings(data))

    return () => {}
    // } else {
    //   dispatch(
    //     ShoetoggelAction.searchUser({ pageNumber: data, key: userSearch })
    //   );

    //   return () => {};
    // }
  }

  const getReports = async () => {
    try {
      const token: any = await localStorage.getItem('kt-auth-react-v')
      const config = {
        headers: {
          Authorization: 'Bearer ' + JSON.parse(token).api_token,
        },
      }

      var cms = ''
      if (circle_id) {
        cms = `&circle_id=${circle_id}`
      }
      // if (vendor_id) {
      //   cms = `&vendor=${vendor_id}`
      // }
      // if (vendor_id) {
      //   cms = `&vendor=${vendor_id}`
      // }
      if (pageNo) {
        cms = cms + `&page=${pageNo}`
      }
      if (vendor_id) {
        cms = cms + `&vendor=${vendor_id}`
      }
      if (vehical_id) {
        cms = cms + `&vehicle=${vehical_id}`
      }

      if (fromdate) {
        cms = cms + `&fromdate=${fromdate}`
      }
      if (todate) {
        cms = cms + `&todate=${todate}`
      }
      if (name) {
        cms = cms + `&search=${name}`
      }
      const res = await axios.get(
        `${URL.API_BASE_URL}${URL.getReports}?approved=pending${cms}`,
        config
      )
      console.log(res?.data?.data?.data)
      if (res?.data?.data?.data) {
        setReportData(res?.data?.data?.data)
        setPageCount(Math.ceil(res?.data?.data?.total / 10))
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message)
    }
  }

  const all_vehicles = async (e: any) => {
    try {
      setVendor_id(e)
      const res = await axios.get(
        `${URL.API_BASE_URL}${URL.all_vendors_tanker}?vendor_id=${e}&page=1`
      )
      console.log(res?.data?.results)
      if (res?.data?.results) {
        setVehical(res?.data?.results)
        setVehical_id('')
        // setVendor_id("")
      }
    } catch (error) {
      console.log(error)
    }
  }

  const fetchCircles = async () => {
    try {
      const response: any = await axios.get(`${URL.API_BASE_URL + URL.getAllCircles}`)
      if (response?.data?.data) {
        setCircles(response?.data?.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const fetchgetAllVendor = async (e: any) => {
    try {
      setCircle_id(e)
      const res = await axios.get(`${URL.API_BASE_URL}${URL.getVendorList}?circle_id=${e}`)
      console.log(res?.data?.results)
      if (res?.data?.results) {
        setVendor(res?.data?.results)
        setVehical_id('')
        setVendor_id('')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const approveBulkReport = async () => {
    try {
      if (approved.length === 0) {
        toast.error('Please select atleast one record')
        return
      }
      const token: any = await localStorage.getItem('kt-auth-react-v')
      const config = {
        headers: {
          Authorization: 'Bearer ' + JSON.parse(token).api_token,
        },
      }
      const res = await axios.post(
        `${URL.API_BASE_URL}/api/admins/approveBulkReport`,
        {ids: approved, approved: 'accept'},
        config
      )
      // console.log(res?.data?.data);
      if (res?.data?.data) {
        getReports()
        toast.success(res?.data?.message)
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message)
    }
  }

  // reject

  const disapproveBulkReport = async () => {
    try {
      if (approved.length === 0) {
        toast.error('Please select atleast one record')
        return
      }
      const token: any = await localStorage.getItem('kt-auth-react-v')
      const config = {
        headers: {
          Authorization: 'Bearer ' + JSON.parse(token).api_token,
        },
      }
      const res = await axios.post(
        `${URL.API_BASE_URL}/api/admins/approveBulkReport`,
        {ids: approved, approved: 'reject'},
        config
      )
      console.log(res?.data?.data)
      if (res?.data?.data) {
        getReports()
        toast.success(res?.data?.message)
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message)
    }
  }

  useEffect(() => {
    fetchCircles()
    getReports()
  }, [pageNo])

  const totalBooking = reportData?.reduce((acc, curr) => acc + curr.amount, 0)
  console.log(totalBooking)

  return (
    <div>
      <div className='d-flex justify-content-between'>
        <h3 className='text-gray-600'>Trip Report Vehicle Wise</h3>
        <p>
          <span className='text-primary'>Home</span> / Trip Report Vehicle Wise
        </p>
      </div>
      <div className={clsx(' p-3 pt-6 shadow m-0 rounded-2x ')}>
        <div className='d-flex px-5 justify-content-between'>
          <div className='me-3' style={{position: 'relative'}}>
            <label>
              <b>Circle</b>
            </label>
            <select
              className='form-control '
              aria-label='Select example'
              name='circle_id'
              onChange={(e) => {
                fetchgetAllVendor(e.target.value)
              }}
            >
              <option value={''}>Select Circle</option>
              {circles?.map((data, i) => {
                return (
                  <option value={data?.id} key={i}>
                    {data?.name}
                  </option>
                )
              })}
            </select>
          </div>

          <div className='me-3' style={{position: 'relative'}}>
            <label>
              <b>Vendor</b>
            </label>{' '}
            <br />
            <select
              className='form-control '
              name=''
              id=''
              value={vendor_id}
              style={{width: '200px'}}
              onChange={(e) => {
                all_vehicles(e.target.value)
              }}
            >
              <option value=''>Select</option>
              {vendor.length > 0 &&
                vendor.map((item: any, index: any) => (
                  <option value={item?.id} key={index}>
                    {item?.name}
                  </option>
                ))}
            </select>
          </div>

          <div className='me-3' style={{position: 'relative'}}>
            <label>
              <b>Vehicle</b>
            </label>{' '}
            <br />
            <select
              className='form-control'
              value={vehical_id}
              onChange={(e) => {
                setVehical_id(e?.target?.value)
              }}
              name=''
              id=''
              style={{width: '200px'}}
            >
              <option value=''>Select</option>
              {vehical.length > 0 &&
                vehical.map((item: any, index: any) => (
                  <option value={item?.vehicle_id} key={index}>
                    {item?.vehicle?.registration_number}
                  </option>
                ))}
            </select>
          </div>

          <div className='me-3'>
            <label>
              <b>Date From:</b>
            </label>{' '}
            <br />
            <input
              className='form-control'
              type='date'
              onChange={(e) => {
                setfromdate(e?.target?.value)
              }}
            />
          </div>
          <div className='me-3'>
            <label>
              <b>Date To:</b>
            </label>{' '}
            <br />
            <input
              className='form-control'
              type='date'
              onChange={(e) => {
                settodate(e?.target?.value)
              }}
            />
          </div>
          <div
            className='  mt-8'
            style={{
              backgroundColor: '#52A841',
              color: 'white',
              height: '30px',
              width: '250px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={() => {
              getReports()
            }}
          >
            View
          </div>
        </div>

        <div className='d-flex px-5 justify-content-end gap-4 align-items-center'>
          <div className='d-flex content-center justify-content-center gap-3 '>
            <input
              onChange={(e) => {
                checkAllData(e.target.checked)
              }}
              type='checkbox'
              id='select'
            />{' '}
            <label htmlFor='select'>
              <b>check / uncheck all</b>
            </label>
          </div>

          <div
            onClick={approveBulkReport}
            className='  mt-8'
            style={{
              backgroundColor: '#ADAFAD',
              color: 'white',
              height: '30px',
              width: '250px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            Approve Selected
          </div>

          <div
            onClick={disapproveBulkReport}
            className='  mt-8'
            style={{
              backgroundColor: '#E21965',
              color: 'white',
              height: '30px',
              width: '250px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            Disapprove Selected
          </div>
        </div>

        <div className='d-flex px-5 justify-content-betq gap-4 align-items-center'>
          <div className='d-flex gap-1'>
            <div
              className=' p-2'
              style={{border: '1px solid #000', backgroundColor: '#ADAFAD'}}
              onClick={() => handleCopy(reportData)}
            >
              Copy
            </div>
            <div
              className=' p-2'
              style={{border: '1px solid #000', backgroundColor: '#ADAFAD'}}
              onClick={() => handleCSV(reportData)}
            >
              CSV
            </div>
            <div
              className=' p-2'
              style={{border: '1px solid #000', backgroundColor: '#ADAFAD'}}
              onClick={() => handlePDF(reportData)}
            >
              PDF
            </div>
            <div
              className=' p-2'
              style={{border: '1px solid #000', backgroundColor: '#ADAFAD'}}
              onClick={() => handleExcel(reportData)}
            >
              EXCEL
            </div>
          </div>

          <div>
            <input
              type='search'
              placeholder='Search wise mobile number'
              className='form-control'
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </div>

        <div className='mt-9'>
          <table className='table' style={{fontSize: '10px', fontWeight: '300'}}>
            <thead className='table-light '>
              <tr>
                <th>S.NO.</th>
                <th>Job Id</th>
                <th>Pickup location</th>
                <th>Drop location</th>
                <th>Pickup Date / time</th>
                <th>End Date / time</th>
                <th>Trip Duration</th>
                <th>Name</th>
                <th>Number</th>
                <th>Tanker Number</th>
                <th>Distance</th>
                <th>Amount</th>
                <th>Remarks</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody style={{maxHeight: '60vh', overflow: 'auto'}}>
              {reportData.length > 0 &&
                reportData.map((item: any, index: any) => (
                  <tr
                    key={index}
                    onClick={() => {
                      approved.includes(item?.id)
                        ? setApproved((prev) =>
                            prev.filter((approvedId) => approvedId !== item?.id)
                          )
                        : setApproved((prev) => [...prev, item?.id])
                    }}
                    className={approved.includes(item?.id) ? 'bg-success text-white' : ''}
                  >
                    <td> {index + 1} </td>
                    <td>{item?.id}</td>
                    <td>{item?.source_hydrant_center?.address}</td>
                    <td>{item?.destination?.address}</td>
                    <td>{item?.date}</td>
                    <td>{item?.complete_time}</td>
                    <td>{item?.trip_duration}</td>

                    <td>{item?.source_hydrant_center?.station_name}</td>
                    <td>{item?.user?.mobile}</td>
                    <td>{item?.vehicle?.registration_number}</td>
                    <td>{item?.total_distance}</td>
                    <td>{item?.amount}</td>
                    <td>{item?.source_hydrant_center?.remark}</td>
                    <td>
                      <Link to={`/view_details/${item?.id}/pending`}>
                        <button className='btn-group btn-group-sm bg-primary text-white'>
                          View Details
                        </button>
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>

            <tfoot>
              <tr>
                <td colSpan={6}></td>
                <td>
                  <b>Date</b>
                </td>
                <td>
                  {' '}
                  {fromdate ? fromdate : '--'} to{todate ? todate : '--'}
                </td>
                <td colSpan={2}></td>
                <td>
                  <b>Total</b>
                </td>
                <td colSpan={2} className='text-center fs-5'>
                  {totalBooking}
                </td>
                <td></td>
              </tr>
              <tr>
                <td colSpan={14} style={{textAlign: 'center'}}>
                  This is System Generated Report it does not require any signature or stamp.
                </td>
              </tr>
            </tfoot>
          </table>
          {/* <p>Showing 1 to 201 of 201 entries</p>  */}
          <div style={{height: '45px'}}>
            <ReactPaginate
              breakLabel='...'
              nextLabel='next'
              className='dataTables_paginate paging_simple_numbers category'
              onPageChange={handlePageClick}
              pageRangeDisplayed={2}
              pageCount={pageCount}
              previousLabel='previous'
              // renderOnZeroPageCount={null}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default VehicalWise
