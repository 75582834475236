import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import {useDispatch, useSelector} from 'react-redux'
import {adminAction} from '../../../redux/common/action'

function Setting() {
  const [show, setShow] = useState(false)
  const [showUpdate, setShowUpdate] = useState(false)

  const handleClose = () => setShow(false)
  const handleCloseUpdate = () => setShowUpdate(false)
  const handleShow = () => setShow(true)
  const handleShowUpdate = () => setShowUpdate(true)

  const dispatch = useDispatch()

  const [settingdata, setsettingdata] = useState<any>({})
  const [settingdataUpdate, setsettingdataUpdate] = useState<any>({})

  const setting: any = useSelector((state: any) => (state.admin.setting ? state.admin.setting : {}))
  //   setting

  useEffect(() => {
    setsettingdataUpdate(setting)
  }, [setting])

  const hendleToChangeSetting = (e: any) => {
    const {name, value} = e.target
    setsettingdata({...settingdata, [name]: value})
  }

  const hendleTosubmitSetting = () => {
    dispatch(adminAction.createAppSettings(settingdata))
    setShow(false)
  }
  const hendleToChangeSettingUpdate = (e: any) => {
    const {name, value} = e.target
    setsettingdataUpdate({...settingdataUpdate, [name]: value})
  }

  const hendleTosubmitSettingUpdate = () => {
    dispatch(adminAction.updateAppSettings(settingdataUpdate))
    setShowUpdate(false)
  }

  useEffect(() => {
    // getAppSettings
    dispatch(adminAction.getAppSettings({}))
  }, [])

  return (
    <div>
      <Modal show={show} dialogClassName='modal-90w' onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add setting</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='fv-row mb-7 col-3'>
              <label className='fs-6 fw-semibold form-label mb-2'>
                <span className='required'>user app name</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='popover'
                  data-bs-trigger='hover'
                  data-bs-html='true'
                  data-bs-content='Permission names is required to be unique.'
                ></i>
              </label>
              <input
                // className='form-control form-control-solid'
                placeholder='Enter user app name'
                name='user_app_name'
                onChange={(e: any) => {
                  hendleToChangeSetting(e)
                }}
                // {...formik.getFieldProps('role_description')}
                className='form-control bg-transparent'
              />
            </div>
            <div className='fv-row mb-7 col-3'>
              <label className='fs-6 fw-semibold form-label mb-2'>
                <span className='required'>user app version code</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='popover'
                  data-bs-trigger='hover'
                  data-bs-html='true'
                  data-bs-content='Permission names is required to be unique.'
                ></i>
              </label>
              <input
                // className='form-control form-control-solid'
                placeholder='Enter user app version code'
                name='user_app_version_code'
                onChange={(e: any) => {
                  hendleToChangeSetting(e)
                }}
                // {...formik.getFieldProps('role_description')}
                className='form-control bg-transparent'
              />
            </div>
            <div className='fv-row mb-7 col-3'>
              <label className='fs-6 fw-semibold form-label mb-2'>
                <span className='required'>user app version name</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='popover'
                  data-bs-trigger='hover'
                  data-bs-html='true'
                  data-bs-content='Permission names is required to be unique.'
                ></i>
              </label>
              <input
                // className='form-control form-control-solid'
                placeholder='Enter user app version name'
                name='user_app_version_name'
                onChange={(e: any) => {
                  hendleToChangeSetting(e)
                }}
                // {...formik.getFieldProps('role_description')}
                className='form-control bg-transparent'
              />
            </div>
            <div className='fv-row mb-7 col-3'>
              <label className='fs-6 fw-semibold form-label mb-2'>
                <span className='required'>user app info</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='popover'
                  data-bs-trigger='hover'
                  data-bs-html='true'
                  data-bs-content='Permission names is required to be unique.'
                ></i>
              </label>
              <input
                // className='form-control form-control-solid'
                placeholder='Enter user app info'
                name='user_app_info'
                onChange={(e: any) => {
                  hendleToChangeSetting(e)
                }}
                // {...formik.getFieldProps('role_description')}
                className='form-control bg-transparent'
              />
            </div>

            <div className='fv-row mb-7 col-3'>
              <label className='fs-6 fw-semibold form-label mb-2'>
                <span className='required'>driver app name</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='popover'
                  data-bs-trigger='hover'
                  data-bs-html='true'
                  data-bs-content='Permission names is required to be unique.'
                ></i>
              </label>
              <input
                // className='form-control form-control-solid'
                placeholder='Enter driver app name'
                name='driver_app_name'
                onChange={(e: any) => {
                  hendleToChangeSetting(e)
                }}
                // {...formik.getFieldProps('role_description')}
                className='form-control bg-transparent'
              />
            </div>
            <div className='fv-row mb-7 col-3'>
              <label className='fs-6 fw-semibold form-label mb-2'>
                <span className='required'>driver app version code</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='popover'
                  data-bs-trigger='hover'
                  data-bs-html='true'
                  data-bs-content='Permission names is required to be unique.'
                ></i>
              </label>
              <input
                // className='form-control form-control-solid'
                placeholder='Enter driver app version code'
                name='driver_app_version_code'
                onChange={(e: any) => {
                  hendleToChangeSetting(e)
                }}
                // {...formik.getFieldProps('role_description')}
                className='form-control bg-transparent'
              />
            </div>
            <div className='fv-row mb-7 col-3'>
              <label className='fs-6 fw-semibold form-label mb-2'>
                <span className='required'>driver app version name</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='popover'
                  data-bs-trigger='hover'
                  data-bs-html='true'
                  data-bs-content='Permission names is required to be unique.'
                ></i>
              </label>
              <input
                // className='form-control form-control-solid'
                placeholder='Enter driver app version name'
                name='driver_app_version_name'
                onChange={(e: any) => {
                  hendleToChangeSetting(e)
                }}
                // {...formik.getFieldProps('role_description')}
                className='form-control bg-transparent'
              />
            </div>
            <div className='fv-row mb-7 col-3'>
              <label className='fs-6 fw-semibold form-label mb-2'>
                <span className='required'>driver app info</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='popover'
                  data-bs-trigger='hover'
                  data-bs-html='true'
                  data-bs-content='Permission names is required to be unique.'
                ></i>
              </label>
              <input
                // className='form-control form-control-solid'
                placeholder='Enter driver app info'
                name='driver_app_info'
                onChange={(e: any) => {
                  hendleToChangeSetting(e)
                }}
                // {...formik.getFieldProps('role_description')}
                className='form-control bg-transparent'
              />
            </div>
            <div className='fv-row mb-7 col-3'>
              <label className='fs-6 fw-semibold form-label mb-2'>
                <span className='required'>staff app name</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='popover'
                  data-bs-trigger='hover'
                  data-bs-html='true'
                  data-bs-content='Permission names is required to be unique.'
                ></i>
              </label>
              <input
                // className='form-control form-control-solid'
                placeholder='Enter staff app name'
                name='staff_app_name'
                onChange={(e: any) => {
                  hendleToChangeSetting(e)
                }}
                // {...formik.getFieldProps('role_description')}
                className='form-control bg-transparent'
              />
            </div>
            <div className='fv-row mb-7 col-3'>
              <label className='fs-6 fw-semibold form-label mb-2'>
                <span className='required'>staff app version code</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='popover'
                  data-bs-trigger='hover'
                  data-bs-html='true'
                  data-bs-content='Permission names is required to be unique.'
                ></i>
              </label>
              <input
                // className='form-control form-control-solid'
                placeholder='Enter staff app version code'
                name='staff_app_version_code'
                onChange={(e: any) => {
                  hendleToChangeSetting(e)
                }}
                // {...formik.getFieldProps('role_description')}
                className='form-control bg-transparent'
              />
            </div>
            <div className='fv-row mb-7 col-3'>
              <label className='fs-6 fw-semibold form-label mb-2'>
                <span className='required'>staff app version name</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='popover'
                  data-bs-trigger='hover'
                  data-bs-html='true'
                  data-bs-content='Permission names is required to be unique.'
                ></i>
              </label>
              <input
                // className='form-control form-control-solid'
                placeholder='Enter staff app version name'
                name='staff_app_version_name'
                onChange={(e: any) => {
                  hendleToChangeSetting(e)
                }}
                // {...formik.getFieldProps('role_description')}
                className='form-control bg-transparent'
              />
            </div>
            <div className='fv-row mb-7 col-3'>
              <label className='fs-6 fw-semibold form-label mb-2'>
                <span className='required'>staff app info</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='popover'
                  data-bs-trigger='hover'
                  data-bs-html='true'
                  data-bs-content='Permission names is required to be unique.'
                ></i>
              </label>
              <input
                // className='form-control form-control-solid'
                placeholder='Enter staff app info'
                name='staff_app_info'
                onChange={(e: any) => {
                  hendleToChangeSetting(e)
                }}
                // {...formik.getFieldProps('role_description')}
                className='form-control bg-transparent'
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
          <Button variant='primary' onClick={hendleTosubmitSetting}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showUpdate} dialogClassName='modal-90w' onHide={handleCloseUpdate}>
        <Modal.Header closeButton>
          <Modal.Title>Update setting</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='fv-row mb-7 col-3'>
              <label className='fs-6 fw-semibold form-label mb-2'>
                <span className='required'>user app name</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='popover'
                  data-bs-trigger='hover'
                  data-bs-html='true'
                  data-bs-content='Permission names is required to be unique.'
                ></i>
              </label>
              <input
                // className='form-control form-control-solid'
                placeholder='Enter user app name'
                name='user_app_name'
                value={settingdataUpdate?.user_app_name}
                onChange={(e: any) => {
                  hendleToChangeSettingUpdate(e)
                }}
                // {...formik.getFieldProps('role_description')}
                className='form-control bg-transparent'
              />
            </div>
            <div className='fv-row mb-7 col-3'>
              <label className='fs-6 fw-semibold form-label mb-2'>
                <span className='required'>user app version code</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='popover'
                  data-bs-trigger='hover'
                  data-bs-html='true'
                  data-bs-content='Permission names is required to be unique.'
                ></i>
              </label>
              <input
                // className='form-control form-control-solid'
                placeholder='Enter user app version code'
                name='user_app_version_code'
                value={settingdataUpdate?.user_app_version_code}
                onChange={(e: any) => {
                  hendleToChangeSettingUpdate(e)
                }}
                // {...formik.getFieldProps('role_description')}
                className='form-control bg-transparent'
              />
            </div>
            <div className='fv-row mb-7 col-3'>
              <label className='fs-6 fw-semibold form-label mb-2'>
                <span className='required'>user app version name</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='popover'
                  data-bs-trigger='hover'
                  data-bs-html='true'
                  data-bs-content='Permission names is required to be unique.'
                ></i>
              </label>
              <input
                // className='form-control form-control-solid'
                placeholder='Enter user app version name'
                name='user_app_version_name'
                value={settingdataUpdate?.user_app_version_name}
                onChange={(e: any) => {
                  hendleToChangeSettingUpdate(e)
                }}
                // {...formik.getFieldProps('role_description')}
                className='form-control bg-transparent'
              />
            </div>
            <div className='fv-row mb-7 col-3'>
              <label className='fs-6 fw-semibold form-label mb-2'>
                <span className='required'>user app info</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='popover'
                  data-bs-trigger='hover'
                  data-bs-html='true'
                  data-bs-content='Permission names is required to be unique.'
                ></i>
              </label>
              <input
                // className='form-control form-control-solid'
                placeholder='Enter user app info'
                name='user_app_info'
                value={settingdataUpdate?.user_app_info}
                onChange={(e: any) => {
                  hendleToChangeSettingUpdate(e)
                }}
                // {...formik.getFieldProps('role_description')}
                className='form-control bg-transparent'
              />
            </div>

            <div className='fv-row mb-7 col-3'>
              <label className='fs-6 fw-semibold form-label mb-2'>
                <span className='required'>driver app name</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='popover'
                  data-bs-trigger='hover'
                  data-bs-html='true'
                  data-bs-content='Permission names is required to be unique.'
                ></i>
              </label>
              <input
                // className='form-control form-control-solid'
                placeholder='Enter driver app name'
                name='driver_app_name'
                value={settingdataUpdate?.driver_app_name}
                onChange={(e: any) => {
                  hendleToChangeSettingUpdate(e)
                }}
                // {...formik.getFieldProps('role_description')}
                className='form-control bg-transparent'
              />
            </div>
            <div className='fv-row mb-7 col-3'>
              <label className='fs-6 fw-semibold form-label mb-2'>
                <span className='required'>driver app version code</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='popover'
                  data-bs-trigger='hover'
                  data-bs-html='true'
                  data-bs-content='Permission names is required to be unique.'
                ></i>
              </label>
              <input
                // className='form-control form-control-solid'
                placeholder='Enter driver app version code'
                name='driver_app_version_code'
                value={settingdataUpdate?.driver_app_version_code}
                onChange={(e: any) => {
                  hendleToChangeSettingUpdate(e)
                }}
                // {...formik.getFieldProps('role_description')}
                className='form-control bg-transparent'
              />
            </div>
            <div className='fv-row mb-7 col-3'>
              <label className='fs-6 fw-semibold form-label mb-2'>
                <span className='required'>driver app version name</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='popover'
                  data-bs-trigger='hover'
                  data-bs-html='true'
                  data-bs-content='Permission names is required to be unique.'
                ></i>
              </label>
              <input
                // className='form-control form-control-solid'
                placeholder='Enter driver app version name'
                name='driver_app_version_name'
                value={settingdataUpdate?.driver_app_version_name}
                onChange={(e: any) => {
                  hendleToChangeSettingUpdate(e)
                }}
                // {...formik.getFieldProps('role_description')}
                className='form-control bg-transparent'
              />
            </div>
            <div className='fv-row mb-7 col-3'>
              <label className='fs-6 fw-semibold form-label mb-2'>
                <span className='required'>driver app info</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='popover'
                  data-bs-trigger='hover'
                  data-bs-html='true'
                  data-bs-content='Permission names is required to be unique.'
                ></i>
              </label>
              <input
                // className='form-control form-control-solid'
                placeholder='Enter driver app info'
                name='driver_app_info'
                value={settingdataUpdate?.driver_app_info}
                onChange={(e: any) => {
                  hendleToChangeSettingUpdate(e)
                }}
                // {...formik.getFieldProps('role_description')}
                className='form-control bg-transparent'
              />
            </div>
            <div className='fv-row mb-7 col-3'>
              <label className='fs-6 fw-semibold form-label mb-2'>
                <span className='required'>staff app name</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='popover'
                  data-bs-trigger='hover'
                  data-bs-html='true'
                  data-bs-content='Permission names is required to be unique.'
                ></i>
              </label>
              <input
                // className='form-control form-control-solid'
                placeholder='Enter staff app name'
                name='staff_app_name'
                value={settingdataUpdate?.staff_app_name}
                onChange={(e: any) => {
                  hendleToChangeSettingUpdate(e)
                }}
                // {...formik.getFieldProps('role_description')}
                className='form-control bg-transparent'
              />
            </div>
            <div className='fv-row mb-7 col-3'>
              <label className='fs-6 fw-semibold form-label mb-2'>
                <span className='required'>staff app version code</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='popover'
                  data-bs-trigger='hover'
                  data-bs-html='true'
                  data-bs-content='Permission names is required to be unique.'
                ></i>
              </label>
              <input
                // className='form-control form-control-solid'
                placeholder='Enter staff app version code'
                name='staff_app_version_code'
                value={settingdataUpdate?.staff_app_version_code}
                onChange={(e: any) => {
                  hendleToChangeSettingUpdate(e)
                }}
                // {...formik.getFieldProps('role_description')}
                className='form-control bg-transparent'
              />
            </div>
            <div className='fv-row mb-7 col-3'>
              <label className='fs-6 fw-semibold form-label mb-2'>
                <span className='required'>staff app version name</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='popover'
                  data-bs-trigger='hover'
                  data-bs-html='true'
                  data-bs-content='Permission names is required to be unique.'
                ></i>
              </label>
              <input
                // className='form-control form-control-solid'
                placeholder='Enter staff app version name'
                name='staff_app_version_name'
                value={settingdataUpdate?.staff_app_version_name}
                onChange={(e: any) => {
                  hendleToChangeSettingUpdate(e)
                }}
                // {...formik.getFieldProps('role_description')}
                className='form-control bg-transparent'
              />
            </div>
            <div className='fv-row mb-7 col-3'>
              <label className='fs-6 fw-semibold form-label mb-2'>
                <span className='required'>staff app info</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='popover'
                  data-bs-trigger='hover'
                  data-bs-html='true'
                  data-bs-content='Permission names is required to be unique.'
                ></i>
              </label>
              <input
                // className='form-control form-control-solid'
                placeholder='Enter staff app info'
                name='staff_app_info'
                value={settingdataUpdate?.staff_app_info}
                onChange={(e: any) => {
                  hendleToChangeSettingUpdate(e)
                }}
                // {...formik.getFieldProps('role_description')}
                className='form-control bg-transparent'
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseUpdate}>
            Close
          </Button>
          <Button variant='primary' onClick={hendleTosubmitSettingUpdate}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <div className='d-flex flex-column flex-column-fluid'>
        <div id='kt_app_toolbar' className='app-toolbar py-3 py-lg-6'>
          <div
            id='kt_app_toolbar_container'
            className='app-container container-xxl d-flex flex-stack'
          >
            <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
              <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
                Setting
              </h1>

              <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
                <li className='breadcrumb-item text-muted'>
                  <Link to={'/'} className='text-muted text-hover-primary'>
                    DashBoard
                  </Link>
                </li>

                <li className='breadcrumb-item'>
                  <span className='bullet bg-gray-400 w-5px h-2px'></span>
                </li>

                <li className='breadcrumb-item text-muted'>Setting</li>
              </ul>
            </div>

            <div className='d-flex align-items-center gap-2 gap-lg-3'>
              <div className='m-0'></div>
            </div>
          </div>
        </div>

        <div id='kt_app_content' className='app-content flex-column-fluid'>
          <div id='kt_app_content_container' className='app-container container-xxl'>
            <div className='card card-flush'>
              <div className='card-header mt-6'>
                <div className='card-title'>
                  <div className='d-flex align-items-center position-relative my-1 me-5'></div>
                  <div className='d-flex align-items-center position-relative my-1 me-5'></div>
                </div>

                <div className='card-toolbar'>
                  {setting ? (
                    <button
                      type='button'
                      className='btn btn-light-primary'
                      onClick={handleShowUpdate}
                    >
                      <span className='svg-icon svg-icon-3'>
                        <svg
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <rect
                            opacity='0.3'
                            x='2'
                            y='2'
                            width='20'
                            height='20'
                            rx='5'
                            fill='currentColor'
                          />
                          <rect
                            x='10.8891'
                            y='17.8033'
                            width='12'
                            height='2'
                            rx='1'
                            transform='rotate(-90 10.8891 17.8033)'
                            fill='currentColor'
                          />
                          <rect
                            x='6.01041'
                            y='10.9247'
                            width='12'
                            height='2'
                            rx='1'
                            fill='currentColor'
                          />
                        </svg>
                      </span>
                      Update Customer
                    </button>
                  ) : (
                    <button type='button' className='btn btn-light-primary' onClick={handleShow}>
                      <span className='svg-icon svg-icon-3'>
                        <svg
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <rect
                            opacity='0.3'
                            x='2'
                            y='2'
                            width='20'
                            height='20'
                            rx='5'
                            fill='currentColor'
                          />
                          <rect
                            x='10.8891'
                            y='17.8033'
                            width='12'
                            height='2'
                            rx='1'
                            transform='rotate(-90 10.8891 17.8033)'
                            fill='currentColor'
                          />
                          <rect
                            x='6.01041'
                            y='10.9247'
                            width='12'
                            height='2'
                            rx='1'
                            fill='currentColor'
                          />
                        </svg>
                      </span>
                      Add Customer
                    </button>
                  )}
                </div>
              </div>

              <div className='card-body pt-0'>
                <div className='row'>
             

                  <div className='col-2'>
                    <p>driver app info</p>
                   
                  </div>
                  <div className='col-2'>
                    <p>{setting?.driver_app_info}</p>
                   
                  </div>
                  <div className='col-2'>
                    <p>driver app name</p>
                   
                  </div>
                  <div className='col-2'>
                    <p>{setting?.driver_app_name}</p>
                   
                  </div>
                  <div className='col-2'>
                    <p>driver app version code</p>
                   
                  </div>
                  <div className='col-2'>
                    <p>{setting?.driver_app_version_code}</p>
                   
                  </div>
                  <div className='col-2'>
                    <p>driver app version name</p>
                   
                  </div>
                  <div className='col-2'>
                    <p>{setting?.driver_app_version_name}</p>
                   
                  </div>
                 
                </div>
                <div className='row'>
             

                  <div className='col-2'>
                    <p>staff app info</p>
                   
                  </div>
                  <div className='col-2'>
                    <p>{setting?.staff_app_info}</p>
                   
                  </div>
                  <div className='col-2'>
                    <p>staff app name</p>
                   
                  </div>
                  <div className='col-2'>
                    <p>{setting?.staff_app_name}</p>
                   
                  </div>
                  <div className='col-2'>
                    <p>staff app version code</p>
                   
                  </div>
                  <div className='col-2'>
                    <p>{setting?.staff_app_version_code}</p>
                   
                  </div>
                  <div className='col-2'>
                    <p>staff app version name</p>
                   
                  </div>
                  <div className='col-2'>
                    <p>{setting?.staff_app_version_name}</p>
                   
                  </div>
                 
                </div>
                <div className='row'>
             

                  <div className='col-2'>
                    <p>user app info</p>
                   
                  </div>
                  <div className='col-2'>
                    <p>{setting?.user_app_info}</p>
                   
                  </div>
                  <div className='col-2'>
                    <p>user app name</p>
                   
                  </div>
                  <div className='col-2'>
                    <p>{setting?.user_app_name}</p>
                   
                  </div>
                  <div className='col-2'>
                    <p>user app version code</p>
                   
                  </div>
                  <div className='col-2'>
                    <p>{setting?.user_app_version_code}</p>
                   
                  </div>
                  <div className='col-2'>
                    <p>user app version name</p>
                   
                  </div>
                  <div className='col-2'>
                    <p>{setting?.user_app_version_name}</p>
                   
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Setting
