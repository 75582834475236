/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100 bg-gray-300'>
    {/* begin::Body */}
    <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
      {/* begin::Form */}
      <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
        {/* begin::Wrapper */}
        <img src='/media/logos/smallLogo.png' alt="Logo" className='w-100px' />
        <p className='fs-1 fw-bold text-primary'>
          Tanker Tracking System
        </p>

        <div className='w-lg-500px p-10 bg-white'>
          <p className='text-center'> Sign in to start your session</p>
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Form */}

      {/* begin::Footer */}
      {/* <div className='d-flex flex-center flex-wrap px-5'>
        
        <div className='d-flex fw-semibold text-primary fs-base'>
          <a href='#' className='px-5' target='_blank'>
            Terms
          </a>

          <a href='#' className='px-5' target='_blank'>
            Plans
          </a>

          <a href='#' className='px-5' target='_blank'>
            Contact Us
          </a>
        </div>
     
      </div> */}
      {/* end::Footer */}
    </div>
    {/* end::Body */}


  </div>
  )
}

export {AuthLayout}
